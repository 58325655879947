<template>
  <el-container class="home_contianer">
    <!--头部区域-->
    <el-header>
      <div class="title">
        <img src="../assets/images/logo.png" alt class="_imge" />
        <span>哈瑞特客服管理系统</span>
      </div>
      <div class="user">
        <div class="callCenter" v-if="isLogin == 0" @click="loginCalling">
          <img src="../assets/images/callImg.png" alt="" />
          <span>请登录呼叫中心</span>
        </div>
        <div class="theCallCenter" v-if="isLogin == 1">
          <!-- 接听方式 -->
          <div class="answerStyle" @click="dialogVisibleCallCenter = true">
            <div class="styleLeft">接听方式</div>
            <div class="styleRight">
              <span>{{ answeringStyle }}</span
              ><i class="el-icon-caret-bottom rightIcon"></i>
            </div>
          </div>

          <!-- 电话状态 -->
          <div class="phoneType">
            <div
              :class="
                callType !== '2' && '1' ? 'typeTime typeTimeActive' : 'typeTime'
              "
            >
              {{ one }}:{{ two }}:{{ three }}
            </div>
            <div class="typeRight">
              <el-select
                v-model="callType"
                placeholder="请选择"
                @change="changeFreeType"
                :class="
                  callType !== '2' && '1'
                    ? 'freeValue freeValueActive'
                    : 'freeValue'
                "
              >
                <el-option
                  v-for="item in freeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- 输入电话号码 -->
          <div class="inputNumber">
            <div class="numberLeft">
              <el-input
                v-model="numberInput"
                class="numberInput"
                placeholder="输入电话号码外呼"
              ></el-input>
            </div>
            <div class="numberBtn">
              <img
                alt=""
                @click="hangupNumber()"
                src="../assets/images/droppedImg.png"
                v-if="isCall || isCallInRinging === 1"
              />
              <img
                src="../assets/images/callImg.png"
                alt=""
                @click="callNumber()"
                v-if="!isCall || isCallInRinging === 1"
              />
            </div>
          </div>
          <!-- 坐席操作 -->
          <div class="operatingBox" v-if="isCall">
            <div
              v-for="(item, i) in operatingList"
              :key="i"
              class="itemBox"
              @click="changeOperatingType(item.name)"
            >
              <img
                alt=""
                :src="item.imgH"
                v-if="isChangeOperating == item.name"
              />
              <img alt="" :src="item.img" v-else />
              <div
                :class="
                  isChangeOperating == item.name
                    ? 'operName operNameH'
                    : 'operName'
                "
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="userAbout">
          <div class="userAbout_items">
            <img src="../assets/images/avatar.png" alt class="weixin_imge" />
            <span>您好,{{ userInfo.name }}</span>
          </div>
        </div>
        <el-button type="primary" @click="logoout">退出</el-button>
      </div>
    </el-header>
    <!--      主体区-->
    <el-container>
      <!--        侧边栏-->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <!--          侧边菜单栏区域-->
        <!--        折叠按钮-->
        <div class="toggle-button" @click="toggleCollpase">|||</div>
        <el-menu
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          :collapse-transition="false"
          background-color="#fff"
          text-color="#000"
          active-text-color="#409EFF"
          :default-active="activeMenu"
          :router="true"
        >
          <div v-for="(item, index) in menuList" :key="index">
            <el-menu-item :index="item.route" v-if="item.children.length === 0">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.label }}</span>
            </el-menu-item>
            <el-submenu :index="item.route" v-if="item.children.length !== 0">
              <template slot="title">
                <i :class="item.icon"></i>
                <span slot="title">{{ item.label }}</span>
              </template>
              <el-menu-item
                :index="sub.route"
                v-for="(sub, _index) in item.children"
                :key="_index"
              >
                <template slot="title">
                  <!--图标区-->
                  <i :class="sub.icon"></i>
                  <span>{{ sub.label }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </div>

          <!--          <el-menu-item index="/ordermanage">-->
          <!--            <i class="el-icon-s-order"></i>-->
          <!--            <span slot="title">工单管理</span>-->
          <!--          </el-menu-item>-->
          <!--          <el-submenu index="/patientlist">-->
          <!--            <template slot="title">-->
          <!--              <i class="el-icon-s-custom"></i>-->
          <!--              <span slot="title">患者管理</span>-->
          <!--            </template>-->
          <!--            <el-menu-item index="/patientlist">-->
          <!--              <template slot="title">-->
          <!--                &lt;!&ndash;图标区&ndash;&gt;-->
          <!--                <i class="el-icon-edit-outline"></i>-->
          <!--                <span>患者列表</span>-->
          <!--              </template>-->
          <!--            </el-menu-item>-->
          <!--            <el-menu-item index="/newpatientdata">-->
          <!--              <template slot="title">-->
          <!--                &lt;!&ndash;图标区&ndash;&gt;-->
          <!--                <i class="el-icon-data-line"></i>-->
          <!--                <span>患者数据</span>-->
          <!--              </template>-->
          <!--            </el-menu-item>-->
          <!--            <el-menu-item index="/editpatient">-->
          <!--              <template slot="title">-->
          <!--                &lt;!&ndash;图标区&ndash;&gt;-->
          <!--                <i class="el-icon-edit-outline"></i>-->
          <!--                <span>患者处理</span>-->
          <!--              </template>-->
          <!--            </el-menu-item>-->
          <!--          </el-submenu>-->
          <!--          <el-menu-item index="/callingrecords">-->
          <!--            <i class="el-icon-phone"></i>-->
          <!--            <span slot="title">呼叫记录</span>-->
          <!--          </el-menu-item>-->
        </el-menu>
      </el-aside>
      <!--        内容区-->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <!-- 呼叫中心 -->
    <el-dialog
      title="呼叫中心"
      :visible.sync="dialogVisibleCallCenter"
      width="410px"
      :modal="false"
      class="dialogVisibleCallCenterBox"
    >
      <div class="phoneStyle">
        <span class="phoneBox">电话类型</span>
        <el-radio v-model="phoneRadio" :label="1" class="phoneRadio"
          >普通电话</el-radio
        >
        <el-radio v-model="phoneRadio" :label="2" class="phoneRadio"
          >IP话机</el-radio
        >
        <el-radio v-model="phoneRadio" :label="3" class="phoneRadio"
          >软电话</el-radio
        >
      </div>
      <div class="blndPhone">
        <span class="bindLeft">绑定电话</span>
        <span class="bindRight">{{
          phoneRadio === 1
            ? phoneObj.normalel
            : phoneRadio === 2
            ? phoneObj.ipTel
            : phoneObj.softTel
        }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelBtn()" class="cancel">取消</el-button>
        <el-button type="primary" @click="confirmBtn()" class="sureBtnBox"
          >确认</el-button
        >
      </span>
    </el-dialog>

    <!-- 通话转移 -->
    <el-dialog
      title="转到座席"
      :visible.sync="dialogVisibletransfer"
      width="517px"
      :modal="false"
      class="dialogVisibleCallCenterBox"
    >
      <div class="topSearch">
        <el-input
          v-model="healthInput"
          placeholder="输入座席号或座席姓名搜索…"
          suffix-icon="el-icon-search"
          class="healthInputBox"
          @input="changeHealth"
        ></el-input>
      </div>
      <div class="contentList">
        <el-collapse accordion v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in contentList"
            :key="item.id"
            :name="item.id"
          >
            <template slot="title">
              <div @click="changeCollapse(index)" style="width: 100%">
                <i
                  class="header-icon el-icon-arrow-up conIcon"
                  v-if="item.flag"
                ></i>
                <i class="header-icon el-icon-arrow-down conIcon" v-else></i>
                {{ item.name }}({{ item.members.length }})
              </div>
            </template>
            <div v-for="(ite, i) in item.members" :key="i" class="itemlist">
              <div class="hisTable">{{ ite.clientName }} ({{ ite.cno }})</div>
              <span>空闲时长：{{ changeTime(ite.stateDuration) }}</span>
              <div class="transferBox" @click="transferCalls(ite.cno)">
                转移
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTransferBtn()" class="cancel">取消</el-button>
        <el-button
          type="primary"
          @click="confirmTransferBtn()"
          class="sureBtnBox"
          >确认</el-button
        >
      </span> -->
    </el-dialog>
    <!-- 来电通话 -->
    <el-dialog
      :visible.sync="dialogVisibleTelephoneCalls"
      width="411px"
      :modal="false"
      class="dialogVisibleCallCenterBox telephoneCallsDialog"
    >
      <div class="contentBox">
        <div class="boxTop">呼叫中心来电</div>
        <div class="boxBottom">
          <div class="btnLeft">
            <div class="name">{{ callName }}</div>
            <div class="phoneNum">{{ callPhone }}</div>
            <div class="callStyle">等待接听…</div>
          </div>
          <div class="btnRight">
            <img
              src="../assets/images/TelephoneHang.png"
              alt=""
              @click="hangupNumber()"
            />
            <img
              src="../assets/images/TelephoneAnswering.png"
              alt=""
              class="TelephoneAnswering"
              @click="callNumber()"
            />
          </div>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "home",
  data() {
    return {
      menulist: [],
      //是否折叠
      isCollapse: false,
      //被激活时的链接状态
      userInfo: "",
      // isLogin:2,
      numberInput: "",
      activeNames: "",
      operatingList: [
        {
          name: "保持",
          img: require("../assets/images/keepImg.png"),
          imgH: require("../assets/images/keepImgH.png"),
        },
        {
          name: "转移",
          img: require("../assets/images/transferImg.png"),
          imgH: require("../assets/images/transferImgH.png"),
        },
        {
          name: "满意度",
          img: require("../assets/images/satisfaction.png"),
          imgH: require("../assets/images/satisfactionH.png"),
        },
        {
          name: "静音",
          img: require("../assets/images/muteImg.png"),
          imgH: require("../assets/images/muteImgH.png"),
        },
      ],
      isChangeOperating: "",
      dialogVisibleCallCenter: false,
      phoneRadio: 3,
      dialogVisibletransfer: false,
      healthManagementValue: "",
      healthInput: "",
      contentList: [],
      contentList2: [],
      answeringStyle: "软电话",
      freeOptions: [
        {
          value: "1",
          label: "忙碌",
        },
        {
          value: "2",
          label: "空闲  ",
        },
      ],
      freeValue: "通话中",
      flag: null,
      one: "00", // 时
      two: "00", // 分
      three: "00", // 秒
      abc: 0, // 秒的计数
      cde: 0, // 分的计数
      efg: 0, // 时的计数
      phoneObj: {
        softTel: "",
        normalel: "",
        ipTel: "",
      },
      menuList: [
        {
          label: "随访管理",
          icon: "el-icon-data-line",
          route: "/taskcenter",
          children: [],
        },
        {
          label: "事件管理",
          icon: "el-icon-s-claim",
          route: "/eventmanage",
          children: [],
        },
        {
          label: "工单管理",
          icon: "el-icon-s-order",
          route: "/ordermanage",
          children: [],
        },
        {
          label: "患者管理",
          icon: "el-icon-s-custom",
          route: "/patientlist",
          children: [],
        },
        {
          label: "呼叫管理",
          icon: "el-icon-phone",
          route: "/callingrecords",
          children: [],
        },
      ],
    };
  },
  computed: {
    activeMenu() {
      return this.$route.path;
    },
    ...mapState({
      isLogin: (state) => state.calling.isLogin,
      isCall: (state) => state.calling.isCall,
      isCallInRinging: (state) => state.calling.isCallInRinging,
      callName: (state) => state.calling.callName,
      callPhone: (state) => state.calling.callPhone,
    }),
    callType: {
      get() {
        return this.$store.state.calling.callType;
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {},
    },
    dialogVisibleTelephoneCalls: {
      get() {
        return this.$store.state.calling.dialogVisibleTelephoneCalls;
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {},
    },
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.startHandler();
    console.log(this.$store.state);
    this.$store.dispatch("calling/initCallingState");
    this.getQueueList();
    this.getBindTel();
  },
  watch: {
    "$store.state.calling.callType"(newVal, oldVal) {
      //对数据执行操作
      this.endHandler();
      this.startHandler();
      console.log(newVal, oldVal);
      if (newVal === "2") {
        this.isChangeOperating = "";
      }
    },
  },
  methods: {
    logoout() {
      this.$store.dispatch("loginAbout/loginOut");
    },
    loginCalling() {
      const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      if (userInfo.isLogin === 1) {
        this.$http.getCallingPhoneList({ cno: userInfo.cno }).then((res) => {
          console.log(res, "=============获取绑定电话============");
          //获取软电话
          let tel = res.result.data.filter((item) => item.telType === 4);
          console.log(tel);
          const params = {
            identifier: userInfo.enterpriseCode, //企业编码 当showEnterprise为false时必填，否则忽略

            cno: userInfo.cno, //座席工号 规则同identifier

            password: userInfo.seatsPassword, // 密码 规则同identifier

            bindTel: tel[0].tel, //绑定电话

            bindType: 3, // 绑定类型，1：普通电话、2：IP话机、3：软电话

            loginStatus: 1, //座席初始登录状态 当showAgentInitStatus为false时必填，否则忽略
          };
          this.$store.dispatch("calling/initCallingState");
          this.$store.dispatch("calling/loginCalling", params);
          this.endHandler();
          this.startHandler();
        });
      } else {
        this.$message.warning("该账号不可用于登录呼叫中心!");
      }
    },
    //左侧菜单的折叠与展开
    toggleCollpase() {
      this.isCollapse = !this.isCollapse;
    },
    // 呼叫中心弹窗取消事件
    cancelBtn() {
      this.dialogVisibleCallCenter = false;
    },

    // 拨打电话//接听电话
    callNumber() {
      console.log("666666666666666666");
      //执行接听电话
      if (this.isCallInRinging === 1) {
        console.log("软电话接听");
        if (this.phoneRadio === 3) {
          this.$store.dispatch("calling/sipLink");
        } else {
          this.$message.error("该通话不支持软电话接听!请在设备接听!");
        }
      } else if (this.isCallInRinging === 0) {
        if (this.numberInput) {
          console.log("拨打电话");
          ClinkAgent.previewOutcall({ tel: this.numberInput });
        } else {
          return this.$message.warning("请填写电话");
        }
      }
    },

    // 挂断电话
    hangupNumber() {
      console.log(this.isCallInRinging, "==========================");
      console.log(this.phoneRadio, "==========================");
      if (this.phoneRadio === 3) {
        console.log("软电话挂断");
        this.$store.dispatch("calling/sipUnlink");
      } else {
        console.log("普通电话挂断");
        ClinkAgent.previewOutcallCancel({});
      }
    },
    // 坐席操作
    changeOperatingType(val) {
      let params = {};

      if (val == "转移") {
        this.dialogVisibletransfer = true;
      } else if (val == "保持") {
        this.isChangeOperating = "保持接回";
        this.operatingList = [
          {
            name: "保持接回",
            img: require("../assets/images/keepBackImg.png"),
            imgH: require("../assets/images/keepBackImgH.png"),
          },
        ];
        console.log("==============保持============================");
        this.$store.dispatch("calling/holdPhone");
      } else if (val == "保持接回") {
        this.isChangeOperating = "保持";
        console.log("==============保持接回============================");
        this.operatingList = [
          {
            name: "保持",
            img: require("../assets/images/keepImg.png"),
            imgH: require("../assets/images/keepImgH.png"),
          },
          {
            name: "转移",
            img: require("../assets/images/transferImg.png"),
            imgH: require("../assets/images/transferImgH.png"),
          },
          {
            name: "满意度",
            img: require("../assets/images/satisfaction.png"),
            imgH: require("../assets/images/satisfactionH.png"),
          },
          {
            name: "静音",
            img: require("../assets/images/muteImg.png"),
            imgH: require("../assets/images/muteImgH.png"),
          },
        ];
        this.$store.dispatch("calling/holdReturnPhone");
      } else if (val == "静音" && this.isChangeOperating != "静音") {
        params = { direction: "in" };
        this.$store.dispatch("calling/forbidVoice");
        console.log("静音");
      } else if (val == "静音" && this.isChangeOperating == "静音") {
        params = { direction: "in" };
        // ClinkAgent.unmute(params);
        this.$store.dispatch("calling/cancelForbidVoice");
        console.log("取消静音");
      } else if (val == "满意度") {
        console.log(val, "-------满意度");
        params = {};
        this.$store.dispatch("calling/investigationCalling");
      }

      if (this.isChangeOperating == val) {
        this.isChangeOperating = "";
      } else {
        this.isChangeOperating = val;
      }
    },
    // 坐席搜索
    changeHealth() {
      let arr = [];
      if (this.healthInput * 1 >= 0) {
        this.contentList2.forEach((item) => {
          item.members.forEach((ite) => {
            if (ite.cno.includes(this.healthInput)) {
              arr.push(item);
            }
          });
        });
        let map = new Map();
        for (let item of arr) {
          map.set(item.id, item);
        }
        arr = [...map.values()];
        this.contentList = arr;
      } else {
        this.contentList2.forEach((item) => {
          item.members.forEach((ite) => {
            if (ite.clientName.includes(this.healthInput)) {
              arr.push(item);
            }
          });
        });
        let map = new Map();
        for (let item of arr) {
          map.set(item.id, item);
        }
        arr = [...map.values()];
        this.contentList = arr;
      }
    },

    // 时间转换
    changeTime(time) {
      // 转换为式分秒
      let h = parseInt((time / 60 / 60) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(time % 60);
      s = s < 10 ? "0" + s : s;
      // 作为返回值返回
      return h + ":" + m + ":" + s;
    },
    // 是否展开坐席队列
    changeCollapse(i) {
      this.contentList[i].flag = !this.contentList[i].flag;
    },
    // 通话转移
    transferCalls(val) {
      console.log("转移电话");
      let params = {
        objectType: 1,
        transferObject: val,
      };
      ClinkAgent.transfer(params);
    },
    // 切换话机状态
    changeFreeType(val) {
      this.endHandler();
      this.startHandler();
      console.log(val);
      if (val === "1") {
        this.$store.dispatch("calling/callingPause");
      }
      if (val === "2") {
        this.$store.dispatch("calling/callingUnPause");
      }
      // console.log(this.freeOptions, "freeOptions");
      // let params = {};
      // if (this.freeValue == 2) {
      //   params = { cno: "2002" };
      //   ClinkAgent.pause_client(params);
      // } else if (this.freeValue == 1) {
      //   params = { cno: "2002" };
      //   ClinkAgent.unpause_client(params);
      // }
    },
    // 呼叫中心弹窗确认事件
    confirmBtn() {
      const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      this.$http.getCallingPhoneList({ cno: userInfo.cno }).then((res) => {
        console.log(res, "=============获取绑定电话============");
        //获取软电话
        let softTel = res.result.data.filter((item) => item.telType === 4);
        let normalel = res.result.data.filter((item) => item.telType === 2);
        let ipTel = res.result.data.filter((item) => item.telType === 3);
        this.dialogVisibleCallCenter = false;
        const params = {
          identifier: userInfo.enterpriseCode, //企业编码 当showEnterprise为false时必填，否则忽略
          cno: userInfo.cno, //座席工号 规则同identifier
          password: userInfo.seatsPassword, // 密码 规则同identifier
          bindTel:
            this.phoneRadio === 1
              ? normalel[0].tel
              : this.phoneRadio === 2
              ? ipTel[0].tel
              : softTel[0].tel, //绑定电话
          bindType: this.phoneRadio, // 绑定类型，1：普通电话、2：IP话机、3：软电话
          loginStatus: 1, //座席初始登录状态 当showAgentInitStatus为false时必填，否则忽略
        };
        this.$store.dispatch("calling/initCallingState");
        this.$store.dispatch("calling/loginCalling", params);
        this.answeringStyle =
          this.phoneRadio == 1
            ? "普通电话"
            : this.phoneRadio == 2
            ? "IP话机"
            : "软电话";
      });
    },
    // 开始计时
    startHandler() {
      this.flag = setInterval(() => {
        if (this.three === 60 || this.three === "60") {
          this.three = "00";
          this.abc = 0;
          if (this.two === 60 || this.two === "60") {
            this.two = "00";
            this.cde = 0;
            if (this.efg + 1 <= 9) {
              this.efg++;
              this.one = "0" + this.efg;
            } else {
              this.efg++;
              this.one = this.efg;
            }
          } else {
            if (this.cde + 1 <= 9) {
              this.cde++;
              this.two = "0" + this.cde;
            } else {
              this.cde++;
              this.two = this.cde;
            }
          }
        } else {
          if (this.abc + 1 <= 9) {
            this.abc++;
            this.three = "0" + this.abc;
          } else {
            this.abc++;
            this.three = this.abc;
          }
        }
      }, 1000);
    },

    // 重新开始计时
    endHandler() {
      clearInterval(this.flag);
      this.one = "00";
      this.two = "00";
      this.three = "00";
      this.abc = 0;
      this.cde = 0;
      this.efg = 0;
    },
    //转移通话
    transferCalling(item) {
      console.log(item);
    },
    // 查询队列列表以及所有坐席
    getQueueList() {
      this.$http
        .getSitStatus()
        .then((res) => {
          console.log(res, "res=======================");
          if (res.result.code == 1) {
            this.contentList = res.result.data.queueAndMemberList;
            this.contentList2 = res.result.data.queueAndMemberList;
            this.activeNames = this.contentList[0].id;
          }
        })
        .catch((err) => {});
    },
    //获取绑定电话
    getBindTel() {
      const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      this.$http.getCallingPhoneList({ cno: userInfo.cno }).then((res) => {
        console.log(res, "=============获取绑定电话============");
        //获取软电话
        this.phoneObj.softTel = res.result.data.filter(
          (item) => item.telType === 4
        )[0].tel;
        this.phoneObj.normalel = res.result.data.filter(
          (item) => item.telType === 2
        )[0].tel;
        this.phoneObj.ipTel = res.result.data.filter(
          (item) => item.telType === 3
        )[0].tel;
      });
    },
  },
};
</script>

<style scoped lang="less">
.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 0 !important;
  align-items: center;
}
.title {
  display: flex;
  align-items: center;
  color: #373d41;
  font-size: 20px;
}
.el-aside {
  background-color: #dddddd;
}
.el-main {
  background-color: #eaedf1;
  padding: 0;
  max-height: calc(100vh - 60px);
  /*max-width: calc(100vw - 200px);*/
  /*min-width: 450px;*/
}
.page-container {
  padding: 12px;
  box-sizing: content-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.home_contianer {
  height: 100vh;
  width: 100vw;
  overflow: auto;
}
.iconfont {
  margin-right: 10px;
}
.el-menu {
  border-right: none !important;
}
.toggle-button {
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.2em;
  cursor: pointer;
}
._imge {
  width: 90px;
  height: 90px;
}
.weixin_imge {
  width: 24px;
  height: 24px;
}
.userAbout,
.userAbout_items,
.user {
  display: flex;
  align-items: center;
  color: #606266;
  margin-right: 15px;
  .callCenter {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .theCallCenter {
    margin-right: 10px;
    display: flex;
    .answerStyle {
      width: 200px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      display: flex;
      cursor: pointer;
      align-items: center;
      .styleLeft {
        height: 40px;
        width: 84px;
        border-right: 1px solid #dcdfe6;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
      }
      .styleRight {
        span {
          margin-left: 24px;
          margin-right: 8px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #606266;
        }
        .rightIcon {
          color: #606266;
        }
      }
    }
    .phoneType {
      width: 200px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      margin-left: 12px;
      display: flex;
      cursor: pointer;
      .typeTime {
        width: 120px;
        height: 40px;
        border-right: 1px solid #dcdfe6;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #909399;
      }

      .typeTimeActive {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #f56c6c;
      }
      .typeRight {
        width: 140px;
        overflow: hidden;
        display: flex;
        align-items: center;
        /deep/.freeValue {
          .el-icon-arrow-up:before {
            content: "";
          }
          .el-input__inner {
            border: none;
            font-size: 14px;
          }
        }
        /deep/.freeValueActive {
          .el-input__inner {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #f56c6c;
          }
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #606266;
          margin-left: 15px;
          margin-right: 10px;
        }
      }
    }
    .inputNumber {
      margin-left: 12px;
      width: 250px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      display: flex;
      /deep/.numberLeft {
        width: 180px;
        height: 40px;
        box-sizing: border-box;
        border-right: 1px solid #dcdfe6;
        .numberInput {
          .el-input__inner {
            height: 40px;
            border: none;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #909399;
            line-height: 14px;
          }
        }
      }
      .numberBtn {
        display: flex;
        flex: 1;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        width: 200px;
      }
    }
    .operatingBox {
      display: flex;
      .itemBox {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
          margin-bottom: 3px;
        }
        .operName {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
        }
        .operNameH {
          color: #4facff;
        }
      }
    }
  }
}
.el-button {
  background: #1296db;
}
.test {
  span {
    color: red;
    font-size: 32px;
  }
}
/deep/ .el-footer {
  height: 40px !important;
  line-height: 40px;
  background: #04163e;
  color: #fff;
}

// 呼叫中心
/deep/ .dialogVisibleCallCenterBox {
  .el-dialog__header {
    padding: 0;
    height: 50px;
    border-bottom: 1px solid #ebeef5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    .el-dialog__headerbtn {
      top: 0;
      right: 16px;
    }
  }
  .el-dialog__body {
    text-align: left;
    padding: 20px;
    .phoneStyle {
      .phoneBox {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
      }
      .phoneRadio {
        margin-right: 0;
        margin-left: 30px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
      }
    }
    .blndPhone {
      margin-top: 20px;
      span {
        margin-right: 30px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #303133;
      }
      .bindLeft {
        font-weight: 400;
      }
      .bindRight {
        font-weight: 500;
      }
    }
    .topSearch {
      display: flex;
      .el-icon-arrow-up:before {
        content: "";
      }
      .healthInputBox {
      }
    }
    .contentList {
      margin-top: 13px;
      .el-collapse-item {
        .el-collapse-item__arrow {
          display: none;
        }
        .el-collapse-item__header {
          background: #f7f8fa;
        }
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
      .itemlist {
        padding: 0 36px;
        box-sizing: border-box;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hisTable {
          width: 120px;
        }
        .transferBox {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4facff;
          cursor: pointer;
        }
      }
      .conIcon {
        margin: 0 10px;
      }
    }
  }
  .el-dialog__footer {
    padding-top: 0;
    .cancel {
      width: 120px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      background: none;
    }
    .sureBtnBox {
      width: 120px;
      height: 40px;
      background: #4facff;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
/deep/.telephoneCallsDialog {
  border-radius: 4px;
  .el-dialog {
    margin-top: 40vh !important;
  }
  .el-dialog__header {
    height: 0;
    .el-dialog__headerbtn {
      top: 0;
      right: 16px;
    }
  }
  .el-dialog__body {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    .contentBox {
      margin: 0;
      height: 234px;
      background: url("../assets/images/callBgmImg.png");
      background-size: 100% 100%;
      background-size: cover;
      .boxTop {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebeef5;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-indent: 20px;
      }

      .boxBottom {
        padding: 0 20px;
        box-sizing: border-box;
        margin-top: 26px;
        display: flex;
        justify-content: space-between;
        .btnLeft {
          .name {
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .phoneNum {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            margin-top: 14px;
          }
          .address {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            margin-top: 9px;
          }
          .callStyle {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 15px;
          }
        }
        .btnRight {
          margin-top: 15px;
          img {
            cursor: pointer;
          }
          .TelephoneAnswering {
            margin-left: 29px;
          }
        }
      }
    }
  }
}
</style>
